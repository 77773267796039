

const api_url = 'https://us-central1-instagram-clone-435a6.cloudfunctions.net/widgets/';
const domain_name = 'https://danki.ly/';



/*
const api_url = 'http://localhost:5001/instagram-clone-435a6/us-central1/widgets/';
const domain_name = 'http://localhost:3000/';
*/

export {api_url,domain_name};