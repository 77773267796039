import {useState,useEffect} from 'react';
import {auth, db, storage} from './firebase.js';

import {api_url,domain_name} from './Api';

export default function Produto(props){

    const [premium,setPremium] = useState(false);
    const [links, setLinks] = useState([]);
    const [login,setLogin] = useState([]);
    

    function deslogar(e){
        e.preventDefault();
        auth.signOut();
        window.location.href="/";
    }

    function redirectPagamento(e){
        e.preventDefault();
        
        const requestOptions = {
            method: 'get',
            headers: { 'Content-Type': 'application/json' },
            
        };
        fetch(api_url+'gerarpagamento')
                .then(response => response.json())
                .then(function(data){
                    window.location.href=data.url;
         });
    }

    function encurtarLink(e){
        e.preventDefault();
        let link = document.querySelector('.linkName').value;
        
       
        const requestOptions = {
            method: 'get',
            headers: { 'Content-Type': 'application/json' },
            
        };
        auth.currentUser.getIdToken(/* forceRefresh */ true).then(function(token) {
            fetch(api_url+'insertlink?token='+token+'&link='+link, requestOptions)
                    .then(response => response.json())
                    .then(function(data){
                        console.log(data.linkfinal);
        });
        })
        

    }

    function atualizarEstadoConta(){
        if(auth.currentUser != null){
            db.collection('links').doc(auth.currentUser.email).collection('sublinks').orderBy("time",'desc')
            .onSnapshot(function(data){
                    
                        setLinks(data.docs)
                   
         })
        auth.currentUser.getIdToken(/* forceRefresh */ true).then(function(token) {
    
            fetch(api_url+'checklogin?token='+token)
            .then(response => response.json())
            .then(function(data){
                setPremium(data.pago);
            }).catch(function(e){
               
            })

        })
    }
    }

    useEffect(() => {

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get('code');

        auth.onAuthStateChanged((val)=>{
            if(val){
            
                setLogin({
                nome: val.displayName,
                email:val.email,
                imagem: val.photoURL,
                uid: val.uid
                });
                alert('Hey! Welcome '+val.displayName);
                
            }

            atualizarEstadoConta();
                
            })
        if(code != null){
            //Validar para ver se pagou.
            fetch(api_url+'confirmarpagamento?code='+code)
                .then(response => response.json())
                .then(function(data){
                    if(data.sucesso == "verdade"){
                       // alert('Pagamento feito com sucesso!');
                        setPremium(true);
                    }else{
                        alert("Falha no pagamento :(");
                    }
         });
                
        }
    
    

    }, [])

    return(
        
        login?.nome != undefined?(
        <div>
        <div className="produto">
            <div className="header-logado">
            <h3>Welcome To Your Main Panel!</h3>
            <h3><img src={login.imagem} /> {login.nome} <a onClick={(e)=>deslogar(e)} href="#">Exit</a></h3>
            </div>

            {
                premium == true?
                <div className="input-encurtador">
                    <form onSubmit={(e)=>encurtarLink(e)}>
                        <input className="linkName" type="text" name="link" placeholder="Create your shorted link..." />
                        <input type="submit" name="acao" value="Short Now!" />
                    </form>
                </div>
                :
                <div className="boxPagtoAll">
               
                <div className="boxPagto">
                    <h2>Premium:</h2>
                    <br/>
                    <p>👉 Unlimited Links Forever.</p>
                    <p>👉 Create Custom Links.</p>
                    <p>👉 Track your links.</p>
                    <p>👉 And much more...</p>
                    <br/>
                    
                    <a className="btnbuy" onClick={(e)=>redirectPagamento(e)} href="#">Pay $1 now</a>
                </div>
                
                </div>
            }
        </div>
        <div className="links">
            <h2>Your Premium Links:</h2>
            {
            links?.map(function(val){
                return(
                    <div>
                    
                    <a href={val.data().redirect}>{domain_name}{val.data().url}</a><br/>
                    </div>
                )
            })
            
            }
            
        </div>
        <br/>
        
        </div>
        )
        :
        <div></div>
        
    )
}