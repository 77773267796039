import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAbINRwFnPUHYeLkeZc0OCMH6YOSbb3Snc",
    authDomain: "instagram-clone-435a6.firebaseapp.com",
    databaseURL: "https://instagram-clone-435a6.firebaseio.com",
    projectId: "instagram-clone-435a6",
    storageBucket: "instagram-clone-435a6.appspot.com",
    messagingSenderId: "366515131293",
    appId: "1:366515131293:web:d491bc01fc2ed040f7d574",
    measurementId: "G-0NPHK59DK8"
});

 

  const db = firebase.firestore();
  
  const storage  = firebase.storage();

  const auth = firebase.auth();

  const provider = new firebase.auth.GoogleAuthProvider();


  export {auth,provider,db,storage};
  